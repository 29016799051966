<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-tag', params: { accountId: this.$route.params.accountId } }">Tags</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ tag.label }} ({{ tag.type }})</h1>
                <p class="text-caption text-center">Tag overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="tag">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Type</p>
                    <p class="mb-0 pb-0">
                        {{ tag.type }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ tag.label }}
                        <v-btn icon color="teal darken-2" @click="editTagLabel">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ tag.comment }}
                        <v-btn icon color="teal darken-2" @click="editTagComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Tag ID</p>
                    <p class="mb-0 pb-0">
                        {{ tag.id }}
                    </p>

                    <!-- TODO: the affected X list should depend on the tag type... instead of hard-coding email contacts -->
                    <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This tag is applied to {{ affectedEmailContactList.length }} email contacts.
                            <!-- TODO: show the affectedEmailContactList -->
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This tag is not currently applied to any email contacts.
                        </span>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, tagId: this.$route.params.tagId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Tag Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editTagLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the tag label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The tag label makes it easy for you to see how the tag is used at a glance.
                            You can change it at any time. It is NOT shown to users.
                        </p>

                        <v-text-field
                            ref="tagLabelInput"
                            v-model="editableTagLabel"
                            label="Label"
                            :rules="newTagLabelRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditTagLabel" :disabled="!isEditTagLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editTagLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editTagCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the tag comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The tag comment is a place where you can add more information for your own reference about how you use the tag.
                            You can change it at any time. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableTagComment" label="Tag comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditTagComment" :disabled="!isEditTagCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editTagCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import TagList from '@/components/account-dashboard/TagList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // TagList,
    },
    data: () => ({
        affectedEmailContactList: [],

        error: null,
        account: null,
        tag: null,
        invite: null,
        // edit form title dialog
        editTagLabelDialog: false,
        editTagCommentDialog: false,
        editableTagComment: null,

        editableTagLabel: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        // TODO: get this list from server (see also CreateTag.vue)
        typeDisplay() {
            switch (this.tag?.type) {
            case 'email_contact':
                return 'Email contact';
            default:
                return 'unknown';
            }
        },
        isEditTagLabelComplete() {
            return typeof this.editableTagLabel === 'string' && this.editableTagLabel.trim().length > 0;
        },
        isEditTagCommentComplete() {
            return typeof this.editableTagComment === 'string' && this.editableTagComment.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadTag() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadTag: true });
                const response = await this.$client.account(this.$route.params.accountId).tag.get(this.$route.params.tagId);
                console.log(`loadTag: response ${JSON.stringify(response)}`);
                if (response) {
                    this.tag = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.tag.comment ??= '';
                    // this.tag.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load tag');
                }
            } catch (err) {
                console.error('failed to load tag', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadTag: false });
            }
        },
        async save(tagAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditTag: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).tag.edit({ id: this.$route.params.tagId }, tagAttr);
                console.log(`saveEditTag: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit tag' });
                return false;
            } catch (err) {
                console.error('failed to edit tag', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit tag' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditTag: false });
            }
        },
        editTagLabel() {
            this.editTagLabelDialog = true;
            this.editableTagLabel = this.tag.label;
        },
        editTagComment() {
            this.editTagCommentDialog = true;
            this.editableTagComment = this.tag.comment;
        },
        async saveEditTagLabel() {
            const isEdited = await this.save({ label: this.editableTagLabel });
            this.editTagLabelDialog = false;
            if (isEdited) {
                this.$set(this.tag, 'label', this.editableTagLabel);
            }
        },
        async saveEditTagComment() {
            const isEdited = await this.save({ comment: this.editableTagComment });
            this.editTagCommentDialog = false;
            if (isEdited) {
                this.$set(this.tag, 'comment', this.editableTagComment);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadTag();
    },
};
</script>
